<template>
  <Body :class="{ 'h-full overflow-hidden': isOpen }"> </Body>
  <div class="font-poppins relative overflow-x-clip">
    <PageHeader></PageHeader>
    <div class="main">
      <KeepAlive>
        <OverlayMenu></OverlayMenu>
      </KeepAlive>
      <slot />
    </div>
    <!-- <ClientOnly> -->
    <ModalsContainer />
    <!-- </ClientOnly> -->

    <PageFooter :class="{ hidden: isOpen }"></PageFooter>
    <Toaster />
  </div>
</template>

<script lang="ts" setup>
import { ModalsContainer } from "vue-final-modal";
import { useHamburgerMenu } from "~/composable/useHamburgerMenu";
const { isOpen } = useHamburgerMenu();
</script>

<style lang="scss">
.main {
  width: 100%;
  min-height: calc(100dvh - 192px);
  padding-top: 80px;
  position: relative;
}
</style>
